import React, { memo, Suspense } from 'react';
import Router, {
    withBrowserRouter, setRouterConfigs, useNavigate,
} from '@hydrogen/elements.core.router';
import { useMode } from '@hydrogen/elements.ui.theme';
import AuthenticationProvider, {
    Secure,
} from '@hydrogen/elements.auth.identity-server';
import { dispatchMemberId } from '@hydrogen/elements.data.wealth.app.use-member-id';
import {
    setAccessToken,
    setRestConfig,
    getConfigData,
} from '@hydrogen/elements.core.rest.transport';
import { InitLanguage } from '@hydrogen/elements.core.i18n';
import {
    setGQLAuthToken, setGQLConfigUrl, setGqlConfig,
} from '@hydrogen/elements.core.gql.transport';
import { initAndStart, removeTokenAndFinish } from '@hydrogen/elements.core.signalr';
import { clearFormStore } from '@hydrogen/elements.ui.configs';
import { routingConfig } from './configs/routing.config';
import { notificationConfig } from './configs/notifications.config';
import { serviceConfig } from './configs/service.config';
import { config } from './configs/identity-server.config';
import { LoaderBase } from './configs/loader-base';
import RestrictAccess from './restrict-access';

setRouterConfigs(routingConfig);
const setSWAccessToken = (accessToken) => {
    window?.navigator?.serviceWorker?.controller?.postMessage({
        type: 'ACCESS_TOKEN',
        payload: accessToken,
    });
};

const withMode = (Component) => (props) => {
    useMode();

    return <Component {...props} />;
};

const redirectUrl = '/dashboard';

function App() {
    const navigate = useNavigate();
    const [isAuth, setAuth] = React.useState(false);

    setRestConfig({
        ...serviceConfig,
        headers: getConfigData().headers,
    });
    setGQLConfigUrl(serviceConfig?.GATEWAY_API_URL || '');
    setGqlConfig({
        urlSuffix: serviceConfig?.GQL_URL_SUFFIX,
    });
    const onLogin = (oidcUser) => {
        dispatchMemberId(+oidcUser.profile.mid);
        setAccessToken(oidcUser.access_token);
        setGQLAuthToken(oidcUser.access_token);
        setSWAccessToken(oidcUser.access_token);
        InitLanguage('AdvisorHub');
        initAndStart({
            ...notificationConfig,
            accessToken: oidcUser.access_token,
            identity: {
                authority: config.authority,
                client_id: config.client_id,
                tenant_uid: config.tenant_uid,
            },
        });

        setAuth(true);
    };

    const onLogout = () => {
        removeTokenAndFinish();
        clearFormStore();
    };

    return (
        <AuthenticationProvider
            loadingComponent={(
                <LoaderBase />
            )}
            configuration={config}
            onUserLoaded={onLogin}
            onLogout={onLogout}
            onRedirect={() => navigate(redirectUrl)}
        >
            <Suspense fallback={(<LoaderBase />)}>
                <Secure>
                    <>
                        {(isAuth && !!getConfigData().headers?.Authorization)
                            ? <RestrictAccess><Router /></RestrictAccess>
                            : <LoaderBase />}
                    </>
                </Secure>
            </Suspense>

        </AuthenticationProvider>
    );
}

export default withBrowserRouter(withMode(memo(App)));
