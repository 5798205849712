import { useSecure } from '@hydrogen/elements.auth.identity-server';
import { serviceApi } from '@hydrogen/elements.core.rest.transport';
import { useQuery } from 'react-query';

// IWC USERS DOESN'T HAVE ACCESS TO BANKS API.
// IF THEY TRY TO ACCESS, THEY WILL BE LOGGED OUT
const useCheckAccess = (eOpts = {}) => {
    const query = useQuery('', () => serviceApi().get('/v1.0/banks'), {
        retry: 0,
        ...eOpts,
    });

    return {
        ...query,
        isLoading: query.isLoading || query.isFetching || query.isIdle,
    };
};

const RestrictAccess = ({ children }) => {
    const { logout } = useSecure();

    const { isLoading } = useCheckAccess({
        onError: (error) => {
            if (error?.response?.status === 404) logout();
        },
    });

    return !isLoading && children;
};

export default RestrictAccess;
